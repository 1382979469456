@font-face {
  font-family: "TTLakes";
  src: url("./fonts/TTLakes/tt_lakes_regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TTLakes";
  src: url("./fonts/TTLakes/tt_lakes_extrabold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TTLakes";
  src: url("./fonts/TTLakes/tt_lakes_condensed_regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: "TTLakes";
  src: url("./fonts/TTLakes/tt_lakes_condensed_bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.title {
  display: flex;
  flex-direction: row-reverse;
}
.language-selector-select {
  margin-left: 2em;
  margin-top:1em;
}
